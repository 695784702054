import React, { useState, useEffect, useContext, createContext } from "react";
import axios from "axios";
import {
  setUserData,
  getLocalUser,
  postNotification,
} from "../../utils/utils.js";
import { useHistory } from "react-router-dom";

const authContext = createContext();

export const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => useContext(authContext);

function useProvideAuth() {
  const [user, setUser] = useState(getLocalUser());
  const [loginError, setLoginError] = useState(false);
  let history = useHistory();

  const signin = (credentials) => {
    credentials.appid = "agua";
    axios
      .post(`${process.env.REACT_APP_SSO_ENDPOINT}/auth/login`, credentials, {
        headers: { appID: "agua" },
      })
      .then((data) => {
        setUserData(data.data);
        setUser(data.data);
        history.push("/lecturas");
        setLoginError(false);
        postNotification(
          "Iniciaste sesión, ahora podés acceder a todos tus servicios habilitados"
        );
      })
      .catch((err) => {
        setLoginError(true);
      });
  };

  useEffect(() => {
    // Cargar los datos si está logueado
    setUser(getLocalUser());
    setLoginError("");
  }, []);

  return { user, signin, loginError };
}
