import React, { useState, useEffect } from "react";
import MbappTopbar from "../mbapp-topbar/mbapp-topbar";
import Rutas from "../mbapp-agua-rutas/mbapp-agua-rutas";
import ReclamoInfo from "../mbapp-147-reclamo-info/mbapp-147-reclamo-info";
import Lecturas from "../mbapp-agua-lecturas/mbapp-agua-lecturas";
import Medidores from "../mbapp-agua-medidores/mbapp-agua-medidores";
import Archivos from "../mbapp-agua-archivos/mbapp-agua-archivos";

import { Route } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import { QueryClient, QueryClientProvider } from "react-query";

import "./mbapp-agua-app.css";
import * as serviceWorkerRegistration from "../../serviceWorkerRegistration";

/**
 * Aplicación principal de agua
 *
 * @version 1.0.0
 *
 */
const MbappApp = () => {
  const queryClient = new QueryClient();
  const [waitingServiceWorker, setWaitingServiceWorker] = useState(null);
  const [isUpdateAvailable, setUpdateAvailable] = useState(false);
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [searchQuery, setSearchQuery] = useState("?va=1");
  const [verAbiertos, setVerAbiertos] = useState(true);
  const [verCerrados, setVerCerrados] = useState(false);
  const [verEspera, setVerEspera] = useState(false);

  useEffect(() => {
    console.log("Register");
    serviceWorkerRegistration.register();
  }, []);

  const handleView = (control, estado) => {
    let iQuery = "?";

    switch (control) {
      case "ABIERTO":
        setVerAbiertos(estado);
        iQuery += estado ? "va=1&" : "";
        iQuery += verCerrados ? "vc=1&" : "";
        iQuery += verEspera ? "ve=1&" : "";
        break;
      case "CERRADO":
        setVerCerrados(estado);
        iQuery += verAbiertos ? "va=1&" : "";
        iQuery += estado ? "vc=1&" : "";
        iQuery += verEspera ? "ve=1&" : "";
        break;
      default:
        setVerEspera(estado);
        iQuery += verAbiertos ? "va=1&" : "";
        iQuery += verCerrados ? "vc=1&" : "";
        iQuery += estado ? "ve=1&" : "";
        break;
    }
    setSearchQuery(iQuery);
  };
  return (
    <div style={{ width: "100%" }}>
      <QueryClientProvider client={queryClient}>
        <MbappTopbar />
        <Route path="/lecturas">
          <Lecturas query={searchQuery} />
        </Route>
        <Route path="/rutas">
          <Rutas text="Listado de rutas" />
        </Route>
        <Route exact path="/medidores">
          <Medidores />
        </Route>
        <Route exact path="/archivos">
          <Archivos />
        </Route>
        <Route exact path="/administracion">
          <ReclamoInfo
            icono="shield"
            text="Administración"
            subtext="Administrar usuarios"
          />
        </Route>
      </QueryClientProvider>
    </div>
  );
};

MbappApp.propTypes = {
  /** Description */
};

MbappApp.defaultProps = {};

export default MbappApp;
