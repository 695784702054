import React from "react";
import PropTypes from "prop-types";
import Toolbar from "../mbapp-147-toolbar/mbapp-147-toolbar";
import Medidor from "../mbapp-agua-medidores-item/mbapp-agua-medidores-item";
/**
 * Component description
 *
 * @see More info https://logico.ar/
 * @version 1.0.0
 *
 */
const MbappAguaMedidores = ({ name }) => {
  return (
    <div className="app">
      <div className="app--panel app--panel--reclamos">
        <Toolbar label="Medidores">
          <div
            style={{
              textAlign: "right",
              marginTop: "-5px",
              paddingRight: "var(--sp-mediano)",
            }}
          >
            <button className="btn btn-default btn-primary">Agregar</button>
          </div>
        </Toolbar>
        <div className="app--panel--reclamos--container allow-overflow-x">
          <table>
            <tr className="table-header">
              <th>Nº</th>
              <th>Padrón</th>
              <th>Titular</th>
              <th>Dirección</th>
              <th>Localidad</th>
              <th>Barrio</th>
              <th>F. Instalación</th>
              <th>Mín. m³</th>
              <th></th>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
};

MbappAguaMedidores.propTypes = {
  /** Description */
  name: PropTypes.string.isRequired,
};

MbappAguaMedidores.defaultProps = {
  name: "",
};

export default MbappAguaMedidores;
