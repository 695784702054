import React, { useState } from "react";
import PropTypes from "prop-types";
import "./mbapp-agua-form-asignar-lecturista.css";
import Combo from "../mbapp-combobox/mbapp-combobox";

/**
 * Component description
 *
 * @see More info https://logico.ar/
 * @version 1.0.0
 *
 */
const MbappAguaFormAsignarLecturista = ({
  getForm,
  listado,
  lecturistaAsignado,
}) => {
  const [form, setForm] = useState({});
  // Levantar estado
  getForm(form);

  return (
    <form className="mbapp-form">
      <div className="row">
        <Combo
          name="listadoLecturistas"
          text="Lecturistas"
          items={listado}
          textFieldName="nombre"
          value={lecturistaAsignado.id}
          onChange={(val) => setForm(val)}
          addNA
        />
      </div>
    </form>
  );
};

MbappAguaFormAsignarLecturista.propTypes = {
  /** Listado de lecturistas */
  listado: PropTypes.string.isRequired,
  lecturistaAsignado: PropTypes.object.isRequired,
};

MbappAguaFormAsignarLecturista.defaultProps = {
  listado: "",
  lecturistaAsignado: {},
};

export default MbappAguaFormAsignarLecturista;
