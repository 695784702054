import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Toolbar from "../mbapp-147-toolbar/mbapp-147-toolbar";
import ListItem from "../mbapp-agua-lectura-item/mbapp-agua-lectura-item";
import ComboBox from "../mbapp-combobox/mbapp-combobox";

import { getUserData, getNombreMes } from "../../utils/utils.js";
import axios from "axios";
import { useQuery } from "react-query";
import Loading from "../mbapp-loading/mbapp-loading";
import ErrorPage from "../mbapp-147-reclamo-info/mbapp-147-reclamo-info";
import { useSnackbar } from "react-simple-snackbar";
import Modal from "../mbapp-modal/mbapp-modal";

import LecturaForm from "../mbapp-agua-lectura-form/mbapp-agua-lectura-form";

/**
 * Component description
 *
 * @see More info https://logico.ar/
 * @version 1.0.0
 *
 */
const MbappAguaLecturas = () => {
  const [ruta, setRuta] = useState(1);
  const [ver, setVer] = useState("leidos");
  const [modalVisible, setModalVisible] = useState(false);
  const [lecturaEditar, setLecturaEditar] = useState({});
  const [lecturaForm, setLecturaForm] = useState({});
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [periodo, setPeriodo] = useState("");

  const { isLoading, error, data, refetch } = useQuery(
    "listadoLecturas",
    () =>
      axios(
        `${process.env.REACT_APP_API_AGUA_ENDPOINT}/lecturas?ruta=${ruta}&ver=${ver}`,
        {
          headers: { Authorization: `Bearer ${getUserData("token")}` },
        }
      ),
    {
      refetchInterval: 10000,
      refetchIntervalInBackground: true,
      onError(err) {
        if (err.toString().includes("401")) {
          window.location.href = "/";
        }
      },
    }
  );

  const handleCambioRuta = (rutaID) => {
    setRuta(rutaID);
  };

  const handleCambioVer = (rutaID) => {
    setVer(rutaID);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_AGUA_ENDPOINT}/periodos/actual`, {
        headers: { Authorization: `Bearer ${getUserData("token")}` },
      })
      .then((data) => {
        setPeriodo(`${getNombreMes(data.data.mes)} ${data.data.anio}`);
        refetch(true);
      });
  }, [ruta, ver, refetch]);

  const handleEdit = (lectura) => {
    setLecturaEditar(lectura);
    setModalVisible(true);
  };

  const handleGuardarLectura = () => {
    axios
      .patch(
        `${process.env.REACT_APP_API_AGUA_ENDPOINT}/lecturas/${lecturaForm.id}`,
        lecturaForm,
        {
          headers: {
            Authorization: `Bearer ${getUserData("token")}`,
            appid: "agua",
          },
        }
      )
      .then((res) => {
        openSnackbar("💾 ¡Registro guardado!");
        setModalVisible(false);
        refetch(true);
      })
      .catch((err) => {
        openSnackbar(
          "⚠️ Hubo un problema al guardar el registro: " + err.message
        );
      });
  };

  if (isLoading) return <Loading color="var(--color-azul)" />;

  if (error) {
    return (
      <ErrorPage
        text={"Algo salió mal: " + error.message}
        subtext={"Vuelva a intentarlo más tarde"}
        icono="cloudLightning"
      />
    );
  }

  const CerrarPeriodo = () => {
    if (
      window.confirm(
        "¿Realmente desea cerrar el período? (una vez cerrado no puede volver a abrirse)"
      )
    ) {
      axios
        .patch(
          `${process.env.REACT_APP_API_AGUA_ENDPOINT}/periodos/cerrar`,
          {},
          {
            headers: {
              Authorization: `Bearer ${getUserData("token")}`,
            },
          }
        )
        .then((res) => {
          openSnackbar("💾 Periodo cerrado");
          refetch(true);
        })
        .catch((err) => {
          openSnackbar(
            "⚠️ Hubo un problema al guardar el registro: " + err.message
          );
        });
    }
  };

  return (
    <div className="app">
      <Modal
        isVisible={modalVisible}
        titulo="Editar lectura"
        onAccept={handleGuardarLectura}
        onClose={() => setModalVisible(false)}
        botonAceptarText="Guardar"
      >
        <LecturaForm
          getForm={(form) => setLecturaForm(form)}
          lecturaEditar={lecturaEditar}
        />
      </Modal>
      <div className="app--panel app--panel--reclamos">
        <Toolbar label={periodo}>
          <div>
            <ComboBox
              comboClass="toolbar-combo"
              name="ruta"
              text="Ruta"
              items={[
                { id: 1, nombre: "1" },
                { id: 2, nombre: "1-1" },
                { id: 3, nombre: "2" },
                { id: 4, nombre: "3" },
                { id: 5, nombre: "4" },
                { id: 6, nombre: "4-1" },
                { id: 7, nombre: "5" },
                { id: 8, nombre: "6" },
                { id: 9, nombre: "6-1" },
                { id: 10, nombre: "7" },
                { id: 11, nombre: "7-1" },
                { id: 12, nombre: "8" },
                { id: 13, nombre: "8-1" },
                { id: 14, nombre: "9" },
                { id: 15, nombre: "9-1" },
              ]}
              textFieldName="nombre"
              onChange={(value) => handleCambioRuta(value.id)}
            ></ComboBox>
          </div>
          <div>
            <ComboBox
              comboClass="toolbar-combo"
              name="ruta"
              text="Ver"
              items={[
                { id: "leidos", nombre: "Leidos" },
                { id: "noleidos", nombre: "Sin leer" },
                { id: "todos", nombre: "Todos" },
              ]}
              textFieldName="nombre"
              onChange={(value) => handleCambioVer(value.id)}
            ></ComboBox>
          </div>
          <div
            style={{
              textAlign: "right",
              marginTop: "-5px",
              paddingRight: "var(--sp-mediano)",
            }}
          >
            <button
              className="btn btn-default btn-primary"
              onClick={() => CerrarPeriodo()}
            >
              Cerrar periodo
            </button>
          </div>
        </Toolbar>
        <div
          className="app--panel--container allow-overflow-x"
          style={{ height: "100%" }}
        >
          <table className="text-left">
            <tr className="table-header">
              <th>#</th>
              <th>Nº</th>
              <th>Padrón</th>
              <th>Titular</th>
              <th>Dirección</th>
              <th>F. Lect. ant.</th>
              <th>Lect. ant.</th>
              <th>F. Lect. act.</th>
              <th>Lect. act.</th>
              <th>Consumo</th>
              <th>Obs. Lecturista</th>
              <th></th>
            </tr>
            {data?.data &&
              data?.data?.map((lectura) => {
                return (
                  <ListItem
                    key={lectura.id}
                    lectura={lectura}
                    onEdit={(val) => handleEdit(val)}
                  />
                );
              })}
          </table>
        </div>
      </div>
    </div>
  );
};

MbappAguaLecturas.propTypes = {
  /** Description */
  name: PropTypes.string.isRequired,
};

MbappAguaLecturas.defaultProps = {
  name: "",
};

export default MbappAguaLecturas;
