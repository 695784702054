import React from "react";
import PropTypes from "prop-types";
import "./mbapp-combobox.css";

/**
 * Combobox con listado de items
 *
 * @version 1.0.0
 *
 */
function MbappComboBox(props) {
  let comboClass = `combobox inputGroup ${props.className}`;

  const handleChange = (e) => {
    props.onChange &&
      props.onChange({
        id: e.target.value,
        [props.textFieldName]: e.target.options[e.target.selectedIndex].text,
      });
  };

  return (
    <div className={comboClass}>
      <select name={props.name} onChange={handleChange}>
        {props.items &&
          props.items.map((item, key) => (
            <option
              value={item.id ? item.id : item._id}
              selected={
                item[props.textFieldName] === props.value ? "true" : null
              }
            >
              {item[props.textFieldName]}
            </option>
          ))}
        {props.addNA && (
          <option value="" selected>
            N/A
          </option>
        )}
      </select>
      <label htmlFor={props.name}>
        {props.required ? "*" : null} {props.text}
      </label>
    </div>
  );
}

MbappComboBox.propTypes = {
  /** Texto del label */
  text: PropTypes.string.isRequired,
  /** Idicador si el primer elemento es N/A */
  addNA: PropTypes.bool,
  /** Funcion onChange */
  onChange: PropTypes.func,
  /** Listado de opciones */
  items: PropTypes.arrayOf(PropTypes.object),
  /** Nombre del campo de texto */
  textFieldName: PropTypes.string,
};

MbappComboBox.defaultProps = {
  text: "",
  addNA: false,
  onChange: null,
  items: [],
  textFieldName: "text",
};

export default MbappComboBox;
