import React from "react";
import PropTypes from "prop-types";
import Icon from "../mbapp-icons/mbapp-icon";
import Dropdown from "../mbapp-dropdown/mbapp-dropdown";
import Item from "../mbapp-menu-item/mbapp-menu-item";

/**
 * Component description
 *
 * @see More info https://logico.ar/
 * @version 1.0.0
 *
 */
const MbappAguaRutasDetalleItem = ({ item }) => (
  <div key={item.id} className="app-agua-detalle-ruta--row">
    <div>{item.numeroMedidor}</div>
    <div>{item.padron}</div>
    <div>{item.nombreTitular}</div>
    <div>{item.direccion}</div>
    <div>
      <Dropdown
        notifications={[]}
        icon="moreVertical"
        iconSize={18}
        className="app-reclamo--more-settings"
        width="14em"
      >
        <ul>
          <li>
            <Item icon="cornerUpLeft" text="Enviar a otra ruta" />
            <Item icon="trash" text="Quitar" />
          </li>
        </ul>
      </Dropdown>
    </div>
  </div>
);

MbappAguaRutasDetalleItem.propTypes = {
  /** Description */
  item: PropTypes.object.isRequired,
};

MbappAguaRutasDetalleItem.defaultProps = {
  item: "",
};

export default MbappAguaRutasDetalleItem;
