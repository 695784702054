import React from "react";
import { Icons } from "./icons";
import PropTypes from "prop-types";

/**
 * Iconos para la interfaz de usuario. Actualmente se usa el pack Feather.
 *
 * @see Iconos https://feathericons.com/
 * @version 1.0.1
 *
 */
const MbappIcon = ({ className, name, size, color }) => (
  <div className={className}>
    <svg
      className="icon"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      dangerouslySetInnerHTML={{ __html: Icons[name] }}
    ></svg>
  </div>
);

MbappIcon.propTypes = {
  /** Nombre del ícono a mostrar */
  name: PropTypes.string.isRequired,
  /** Color del ícono, por defecto toma el color del parent */
  color: PropTypes.string,
  /** Tamaño en px del ícono */
  size: PropTypes.number,
  /** Nombre de la clase de estilo CSS */
  fullRender: PropTypes.bool,
};

MbappIcon.defaultProps = {
  name: "''",
  size: 48,
  color: "currentColor",
  fullRender: false,
};

export default MbappIcon;
