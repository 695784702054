import React from "react";
import PropTypes from "prop-types";
import Dropdown from "../mbapp-dropdown/mbapp-dropdown";
import Item from "../mbapp-menu-item/mbapp-menu-item";

/**
 * Component description
 *
 * @see More info https://logico.ar/
 * @version 1.0.0
 *
 */
const MbappAguaMedidoresItem = ({ name }) => {
  return (
    <tr>
      <td>1234</td>
      <td>AB1124</td>
      <td>Juan Pérez</td>
      <td>Zapiola 1333</td>
      <td>BOLÍVAR</td>
      <td>AIRES DE JACARANDA</td>
      <td>05/06/2015</td>
      <td>200</td>
      <td>
        <Dropdown
          notifications={[]}
          icon="moreVertical"
          iconSize={20}
          className="app-reclamo--more-settings"
          width="9em"
        >
          <ul>
            <li>
              <Item icon="edit" text="Editar" />
              <Item icon="trash" text="Quitar" />
            </li>
          </ul>
        </Dropdown>
      </td>
    </tr>
  );
};

MbappAguaMedidoresItem.propTypes = {
  /** Description */
  name: PropTypes.string.isRequired,
};

MbappAguaMedidoresItem.defaultProps = {
  name: "",
};

export default MbappAguaMedidoresItem;
