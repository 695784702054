import React from "react";
import PropTypes from "prop-types";
import { formatFecha } from "../../utils/utils.js";
import Icon from "../mbapp-icons/mbapp-icon";
import Pulsar from "../mbapp-pulsar/mbapp-pulsar";

/**
 * Component description
 *
 * @see More info https://logico.ar/
 * @version 1.0.0
 *
 */
const MbappAguaLecturaItem = ({ lectura, onEdit }) => {
  return (
    <tr key={lectura.id}>
      <td>{lectura.orden}</td>
      <td>{lectura.numeroMedidor}</td>
      <td>{lectura.padron}</td>
      <td>{lectura.nombreTitular}</td>
      <td>{lectura.direccion}</td>
      <td>{formatFecha(lectura.anteriorFechaLectura)}</td>
      <td>{lectura.anteriorLectura}</td>
      <td>{lectura.leido ? formatFecha(lectura.fechaLectura) : "-"}</td>
      <td>{lectura.leido ? lectura.lectura : "-"}</td>
      <td
        className={
          lectura.lectura - lectura.anteriorLectura > 60 ||
          lectura.lectura - lectura.anteriorLectura < 0
            ? "text-danger"
            : ""
        }
      >
        <b>
          {lectura.leido && lectura.lectura - lectura.anteriorLectura < 0 && (
            <Pulsar tipo="rojo" texto="Error de lectura" direccion="top" />
          )}
          {lectura.leido
            ? (lectura.lectura - lectura.anteriorLectura).toFixed(2)
            : "0"}
        </b>
      </td>
      <td>{lectura.observaciones}</td>
      <td>
        <button className="btn btn-action" onClick={() => onEdit(lectura)}>
          <Icon name="edit" size={18} />
        </button>
      </td>
    </tr>
  );
};

MbappAguaLecturaItem.propTypes = {
  /** Description */
  name: PropTypes.string.isRequired,
};

MbappAguaLecturaItem.defaultProps = {
  name: "",
};

export default MbappAguaLecturaItem;
