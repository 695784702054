import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import AppAgua from "./components/mbapp-agua-app/mbapp-agua-app";
import MbappSidebar from "./components/mbapp-sidebar/mbapp-sidebar";
import Login from "./components/react-mbapp-login/react-mbapp-login";
import SnackbarProvider from "react-simple-snackbar";

import reportWebVitals from "./reportWebVitals";

import LandingPage from "./components/mbapp-147-landing/mbapp-147-landing";

import Notification from "./components/mbapp-system-notifications/mbapp-system-notifications.jsx";

import {
  ProvideAuth,
  useAuth,
} from "./components/mbapp-hook-auth/mbapp-hook-auth";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

const PrivateRoute = ({ children, ...rest }) => {
  const auth = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect to={{ pathname: "/", state: { from: location } }} />
        )
      }
    />
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Notification />
    <SnackbarProvider>
      <Router>
        <ProvideAuth>
          <Switch>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/">
              <LandingPage />
            </Route>
            <PrivateRoute path="/">
              <div style={{ display: "flex", flexDirection: "row" }}>
                <MbappSidebar appName="agua" />
                <div style={{ width: "100%" }}>
                  <AppAgua />
                </div>
              </div>
            </PrivateRoute>
          </Switch>
        </ProvideAuth>
      </Router>
    </SnackbarProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

//serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
