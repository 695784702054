import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import TextBox from "../mbapp-textbox/mbapp-textbox";

/**
 * Component description
 *
 * @see More info https://logico.ar/
 * @version 1.0.0
 *
 */
const AguaLecturaForm = ({ getForm, lecturaEditar }) => {
  const [form, setForm] = useState({});

  // Cargar o resetear el formulario
  // los campos son los mismos que devuelve la API del CORE
  useEffect(() => {
    // Setear el formulario
    setForm({
      id: lecturaEditar?.id ? lecturaEditar.id : 0,
      lectura: lecturaEditar?.lectura ? lecturaEditar.lectura : 0,
      observaciones: lecturaEditar?.observaciones
        ? lecturaEditar.observaciones
        : "",
    });
  }, [lecturaEditar]);

  // Levantar estado
  getForm(form);

  // Actualiza el estado del formulario
  const handleChange = (field, val) => {
    setForm((prevState) => ({
      ...prevState,
      [field]: val,
    }));
  };

  return (
    <form className="mbapp-form">
      <div className="row">
        <TextBox
          name="lectura"
          label="Lectura"
          type="numeric"
          size="8"
          enterhint="next"
          placeholder="Ingresar la lectura (con punto decimal)"
          onChange={(value) => handleChange("lectura", value)}
          value={form.lectura}
          required
        />
      </div>
      <div className="row">
        <TextBox
          name="observaciones"
          label="Observaciones"
          type="text"
          enterhint="next"
          placeholder="Ingresar una observación"
          onChange={(value) => handleChange("observaciones", value)}
          value={form.observaciones}
        />
      </div>
    </form>
  );
};

AguaLecturaForm.propTypes = {
  /** Description */
  name: PropTypes.string.isRequired,
};

AguaLecturaForm.defaultProps = {
  name: "",
};

export default AguaLecturaForm;
