import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Route, Switch, useLocation } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import InfoPage from "../mbapp-147-reclamo-info/mbapp-147-reclamo-info";

import ListadoRutas from "../mbapp-agua-rutas-listado/mbapp-agua-rutas-listado";
import RutasDetalle from "../mbapp-agua-rutas-detalle/mbapp-agua-rutas-detalle";

import "./mbapp-agua-rutas.css";

/**
 * Aplicación de tipo de reclamo
 *
 * @version 1.0.0
 *
 */
const MbappAguaRutas = () => {
  const queryClient = new QueryClient();
  const location = useLocation();
  const [showPanel, setShowPanel] = useState(false);

  useEffect(() => {
    if (location.pathname.startsWith("/rutas/detalle/")) {
      setShowPanel(true);
    } else {
      setShowPanel(false);
    }
  }, [location]);

  return (
    <div className="app app--rutas">
      <div className="app--panel app--panel--reclamos">
        <Route path="/rutas">
          <QueryClientProvider client={queryClient}>
            <ListadoRutas text="Listado de rutas" />
          </QueryClientProvider>
        </Route>
      </div>
      <div
        className={`app--panel app--panel--detalle ${
          showPanel ? "app--panel--detalle__show" : ""
        }`}
      >
        <Switch>
          <Route path="/rutas/detalle/:idRuta">
            <RutasDetalle text="Administrar ruta" />
          </Route>
          <Route exact path="/rutas">
            <InfoPage
              text={"Seleccionar una ruta"}
              subtext={"para administrarla"}
              icono="alertCircle"
            />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

MbappAguaRutas.propTypes = {
  /** Description */
  name: PropTypes.string.isRequired,
};

MbappAguaRutas.defaultProps = {
  name: "",
};

export default MbappAguaRutas;
