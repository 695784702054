import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Toolbar from "../mbapp-147-toolbar/mbapp-147-toolbar";
import RutaDetalleItem from "../mbapp-agua-rutas-detalle-item/mbapp-agua-rutas-detalle-item";
import "./mbapp-agua-rutas-detalle.css";
import { ReactSortable } from "react-sortablejs";
import Icon from "../mbapp-icons/mbapp-icon";
import Dropdown from "../mbapp-dropdown/mbapp-dropdown";
import Item from "../mbapp-menu-item/mbapp-menu-item";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import { useSnackbar } from "react-simple-snackbar";
import { getUserData } from "../../utils/utils.js";
import "../mbapp-147-reclamo-detalle/mbapp-147-reclamo-detalle.css";

import FormLecturista from "../mbapp-agua-form-asignar-lecturista/mbapp-agua-form-asignar-lecturista";
import Modal from "../mbapp-modal/mbapp-modal";

/**
 * Component description
 *
 * @see More info https://logico.ar/
 * @version 1.0.0
 *
 */

const MbappAguaRutasDetalle = ({ text }) => {
  let { idRuta } = useParams();
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [state, setState] = useState([]);
  const [modalLecturistaVisible, setModalLecturistaVisible] = useState(false);
  const [listadoLecturistas, setListadoLecturistas] = useState();
  const [lecturistaAsignado, setLecturistaAsignado] = useState();
  const [nuevoLecturista, setNuevoLecturista] = useState();

  useEffect(() => {
    if (idRuta) {
      axios
        .get(
          `${process.env.REACT_APP_API_AGUA_ENDPOINT}/lecturas?ruta=${idRuta}&ver=todos`,
          {
            headers: { Authorization: `Bearer ${getUserData("token")}` },
          }
        )
        .then((data) => {
          setState(data.data);
        })
        .catch((err) => {});
    }
  }, [idRuta]);

  const AbrirModalLecturistas = () => {
    setModalLecturistaVisible(true);
    axios
      .get(`${process.env.REACT_APP_API_AGUA_ENDPOINT}/lecturistas`, {
        headers: { Authorization: `Bearer ${getUserData("token")}` },
      })
      .then((data) => {
        setListadoLecturistas(data.data);
      })
      .catch((err) => {});
  };

  const ActualizarLecturista = () => {
    setModalLecturistaVisible(false);
    axios
      .patch(
        `${process.env.REACT_APP_API_AGUA_ENDPOINT}/lecturas/asignar?ruta=${idRuta}&lecturista=${nuevoLecturista.id}`,{},
        {
          headers: {
            Authorization: `Bearer ${getUserData("token")}`,
          },
        }
      )
      .then((res) => {
        setLecturistaAsignado({});
        openSnackbar("💾 ¡Lecturista asignado!");
      })
      .catch((err) => {
        setLecturistaAsignado({});
        openSnackbar(
          "⚠️ Hubo un problema al asignar lecturista: " + err.message
        );
      });
  };

  return (
    <div>
      <Modal
        isVisible={modalLecturistaVisible}
        titulo="Asignar lecturista"
        onAccept={() => ActualizarLecturista()}
        onClose={() => setModalLecturistaVisible(false) && setLecturistaAsignado({})}
        botonAceptarText="Asignar"
      >
        <FormLecturista
          getForm={(form) => setNuevoLecturista(form)}
          listado={listadoLecturistas}
          lecturistaAsignado={lecturistaAsignado}
        />
      </Modal>
      <div className="app-reclamo-detalle">
        <Toolbar label={text} backTo="/rutas">
          <div style={{ marginRight: "var(--sp-grande)", textAlign: "right" }}>
            <Dropdown
              notifications={[]}
              icon="moreVertical"
              iconSize={20}
              className="app-reclamo--more-settings"
              width="13em"
            >
              <ul>
                <li>
                  <Item
                    icon="edit"
                    text="Asignar lecturista"
                    onClick={() => AbrirModalLecturistas()}
                  />
                  <Item icon="plus" text="Agregar medidor" />
                </li>
              </ul>
            </Dropdown>
          </div>
        </Toolbar>
      </div>
      <div path="/rutas/detalle/:idArea">
        <div className="app--panel--tipo_reclamo--listado">
          <div className="app--panel--reclamos--container app--panel--areas--container">
            <div className="app-agua-detalle-ruta--table">
              <div className="table-header">
                <div className="app-agua-detalle-ruta--row">
                  <div>Medidor</div>
                  <div>Padrón</div>
                  <div>Titular</div>
                  <div>Dirección</div>
                  <div>Acciones</div>
                </div>
              </div>
              {state.map((item) => (
                <RutaDetalleItem item={item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MbappAguaRutasDetalle.propTypes = {
  /** Description */
  text: PropTypes.string.isRequired,
};

MbappAguaRutasDetalle.defaultProps = {
  text: "",
};

export default MbappAguaRutasDetalle;
