import React from "react";
import PropTypes from "prop-types";
import Toolbar from "../mbapp-147-toolbar/mbapp-147-toolbar";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import axios from "axios";
import { getUserData } from "../../utils/utils.js";

/**
 * Component description
 *
 * @see More info https://logico.ar/
 * @version 1.0.0
 *
 */
const MbappAguaRutasListado = ({ text }) => {
  const { isLoading, error, data, refetch } = useQuery(
    `listaRutas`,
    () =>
      axios(`${process.env.REACT_APP_API_AGUA_ENDPOINT}/rutas`, {
        headers: { Authorization: `Bearer ${getUserData("token")}` },
      }),
    {
      refetchInterval: 10000,
      refetchIntervalInBackground: true,
    }
  );

  return (
    <div>
      <Toolbar label={text}></Toolbar>
      <div className="app--panel--reclamos--container app--panel--areas--container">
        {data?.data.map((ruta) => {
          return (
            <Link to={`/rutas/detalle/${ruta.id}`}>
              <p className="app--areas--item">{ruta.nombre}</p>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

MbappAguaRutasListado.propTypes = {
  /** Description */
  text: PropTypes.string.isRequired,
};

MbappAguaRutasListado.defaultProps = {
  text: "",
};

export default MbappAguaRutasListado;
